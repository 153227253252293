// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import { couponPages } from "@/config/couponPages";

// Define routes
const routes = [
  // qrviewer
  {
    path: '/',
    name: 'indexHome',
    component: () => import('@/components/indexHome.vue'),
  },
  {
    path: '/img/:id',
    name: 'qrviewer',
    component: () => import('@/components/qrviewer/qrViewer.vue'),
    props: (route) => ({
      imageCode: route.params.id
    })
  },
  // coupon pages
  {
    path: "/coupon/:id",
    name: "couponMain",
    component: () => import('@/components/coupon/couponMain.vue'),
    props: (route) => ({
      couponData: couponPages[route.params.id]
    }),
    children: [
      {
        path: "location",
        name: "couponLocation",
        component: () => import('@/components/coupon/couponLocation.vue')
      },
      {
        path: "navigation",
        name: "couponNavigation",
        component: () => import('@/components/coupon/couponNavigation.vue'),
      },
      {
        path: "kiosk",
        name: "couponKiosk",
        component: () => import('@/components/common/howto/useKiosk.vue'),
      },
      {
        path: "error",
        name: "couponBoothError",
        component: () => import('@/components/common/howto/boothError.vue'),
      },
    ]
  },
  // howtouse
  {
    path: "/howtouse",
    name: "howtouse",
    component: () => import('@/components/common/howto/howtoUse.vue'),
    children: [
      {
        path: "kiosk",
        name: "kiosk",
        component: () => import('@/components/common/howto/useKiosk.vue'),
      },
      {
        path: "highangle",
        name: "kiosk_high",
        component: () => import('@/components/common/howto/useKiosk.vue'),
      },
      {
        path: "eyelevel",
        name: "kiosk_eye",
        component: () => import('@/components/common/howto/useKiosk.vue'),
      },
      {
        path: "error",
        name: "error",
        component: () => import('@/components/common/howto/boothError.vue'),
      },
    ],
  },
]

// Create the router instance and pass the `routes` option
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
